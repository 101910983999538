import { Injectable } from "@angular/core";

import { LoggerService } from "../logger/logger.service";

@Injectable({
  providedIn: "root",
})
export class WebCryptoService {
  constructor(private loggerService: LoggerService) {}

  // More info - https://medium.com/perimeterx/fun-times-with-webcrypto-part-2-encrypting-decrypting-dfb9fadba5bc
  // More info - https://gist.github.com/andreburgaud/6f73fd2d690b629346b8

  // @TODO
  // Need to find a way to store encrypted value in session storage

  private async getDerivation(
    hash: string,
    salt: string,
    password: string,
    iterations: number,
    keyLength: number
  ) {
    try {
      const textEncoder = new TextEncoder();
      const passwordBuffer = textEncoder.encode(password);
      const importedKey = await crypto.subtle.importKey(
        "raw",
        passwordBuffer,
        "PBKDF2",
        false,
        ["deriveBits"]
      );

      const saltBuffer = textEncoder.encode(salt);
      const params = {
        name: "PBKDF2",
        hash: hash,
        salt: saltBuffer,
        iterations: iterations,
      };
      const derivation = await crypto.subtle.deriveBits(
        params,
        importedKey,
        keyLength * 8
      );
      return derivation;
    } catch (error) {
      this.loggerService.error(
        'Error in running "getDerivation()" from web-crypto service',
        error
      );
      return error;
    }
  }

  private async getKey(derivation) {
    try {
      const ivlen = 16;
      const keylen = 32;
      const derivedKey = derivation.slice(0, keylen);
      const iv = derivation.slice(keylen);
      const importedEncryptionKey = await crypto.subtle.importKey(
        "raw",
        derivedKey,
        { name: "AES-CBC" },
        false,
        ["encrypt", "decrypt"]
      );

      return {
        key: importedEncryptionKey,
        iv: iv,
      };
    } catch (error) {
      this.loggerService.error(
        'Error in running "getKey()" from web-crypto service',
        error
      );
      return error;
    }
  }

  private async encrypt(text: string, keyObject) {
    try {
      const textEncoder = new TextEncoder();
      const textBuffer = textEncoder.encode(text);
      const encryptedText = await crypto.subtle.encrypt(
        { name: "AES-CBC", iv: keyObject.iv },
        keyObject.key,
        textBuffer
      );

      return encryptedText;
    } catch (error) {
      this.loggerService.error(
        'Error in running "encrypt()" from web-crypto service',
        error
      );
      return error;
    }
  }

  private async getDerivationAndKey() {
    try {
      // we have to know all of these properties before calling the encryption method
      const hash = "SHA-256";
      const salt = "SALT";
      const password = "PASSWORD";
      const iterations = 1000;
      const keyLength = 48;
      const derivation = await this.getDerivation(
        hash,
        salt,
        password,
        iterations,
        keyLength
      );
      const keyObject = await this.getKey(derivation);

      return keyObject;
    } catch (error) {
      this.loggerService.error(
        'Error in running "getDerivationAndKey()" from web-crypto service',
        error
      );
      return error;
    }
  }

  public async initEncrypt(payload: string | number | Record<string, unknown>) {
    try {
      // const encryptObj = {
      //   id: "7f85f6db-7894-418d-996c-f3f4ac61bf8e",
      //   sellerScore: 80,
      //   salesCount: 5,
      // };
      // console.log('encryptObj', encryptObj);

      console.log("payload", payload);

      // we have to know all of these properties before calling the encryption method
      // const hash = "SHA-256";
      // const salt = "SALT";
      // const password = "PASSWORD";
      // const iterations = 1000;
      // const keyLength = 48;
      // const derivation = await this.getDerivation(
      //   hash,
      //   salt,
      //   password,
      //   iterations,
      //   keyLength
      // );
      // const keyObject = await this.getKey(derivation);

      const keyObject = await this.getDerivationAndKey();

      // calling encrypt
      const encryptedObject = await this.encrypt(
        JSON.stringify(payload),
        keyObject
      );

      // Object.getPrototypeOf(encryptedObject);
      // console.log("encryptedObject", encryptedObject);
      
      this.initDecrypt(encryptedObject);
    } catch (error) {
      this.loggerService.error(
        'Error in running "initEncrypt()" from web-crypto service',
        error
      );
      return error;
    }
  }

  private async decrypt(encryptedText, keyObject) {
    try {
      const textDecoder = new TextDecoder("utf-8");
      const decryptedText = await crypto.subtle.decrypt(
        { name: "AES-CBC", iv: keyObject.iv },
        keyObject.key,
        encryptedText
      );

      return textDecoder.decode(decryptedText);
    } catch (error) {
      this.loggerService.error(
        'Error in running "decrypt()" from web-crypto service',
        error
      );
      return error;
    }
  }

  public async initDecrypt(encryptedObject) {
    try {
      // These are the exact same parameters we used in our encryption method.
      // All of these has to be the same in order for us to be able to decrypt the object successfully.
      // const hash = "SHA-256";
      // const salt = "SALT";
      // const password = "PASSWORD";
      // const iterations = 1000;
      // const keyLength = 48;
      // const derivation = await this.getDerivation(
      //   hash,
      //   salt,
      //   password,
      //   iterations,
      //   keyLength
      // );
      // const keyObject = await this.getKey(derivation);

      const keyObject = await this.getDerivationAndKey();

      // calling encrypt
      const decryptedObject = await this.decrypt(encryptedObject, keyObject); // encryptedObject is the encoded object from the previous section

      console.log("decryptedObject", JSON.parse(decryptedObject));
    } catch (error) {
      this.loggerService.error(
        'Error in running "initDecrypt()" from web-crypto service',
        error
      );
      return error;
    }
  }
}
