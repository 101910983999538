import { Injectable } from '@angular/core';

import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { ApiResponse } from 'src/app/shared/interfaces/api-response';
import { InvitedUsers } from 'src/app/shared/interfaces/invited-users';
import { Observable } from 'rxjs';
import { OrganisationsService } from '../organisations/organisations.service';

@Injectable({
  providedIn: 'root'
})
export class InvitedUsersService {

  constructor(
    private httpClient: HttpClient,
    private organisationsService: OrganisationsService
  ) { }

  insert(parameters: InvitedUsers) {
    return this.httpClient.post<ApiResponse>('/invited/insert', parameters, {
      observe: 'response',
    });
  }
  
  public getAll(): Observable<HttpResponse<ApiResponse>> {
    const organisationId = this.organisationsService.getCurrentOrganisationId();

    const params = new HttpParams().append(
      "organisationId",
      organisationId.toString()
    );

    return this.httpClient.get<ApiResponse>("/invited/get-all", {
      params,
      observe: "response",
    });
  }

  delete(invitedUserId: number) {
    return this.httpClient.post<ApiResponse>('/invited/delete', {
      invitedUserId,
    }, {
      observe: 'response',
    });
  }
}
