/**
 * @author Jyothish Mathew
 * This is a sharing module. Purpose of this is to avoid duplicate imports.
 * This was imported in app.module.ts and playlist.module.ts in order to make
 * layout work, but no longer using this because layout is unnecessarily
 * recreated on each route change.
 * https://blog.angularindepth.com/angular-routing-reusing-common-layout-for-pages-from-different-modules-440a23f86b57
 * 
 * Lets see whether we need this or not as the app progresses?
 * CAN DELETE IF NO LONGER NEEDED
 * 
 * https://angular.io/guide/sharing-ngmodules
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { BootstrapModule } from '../../bootstrap.module';
// import { HeaderComponent } from '../layout/header/header.component';
// import { FooterComponent } from '../layout/footer/footer.component';
// import { LayoutComponent } from '../layout/layout/layout.component';
import { LoaderComponent } from '../components/loader/loader.component';
import { AlertComponent } from '../components/alert/alert.component';
import { FilesizePipe } from '../../shared/pipes/filesize/filesize.pipe';
import { NgbNavModule, NgbAlertModule, NgbTooltipModule, NgbTooltipConfig, NgbProgressbarModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastComponent } from '../components/toast/toast.component';
import { InfoComponent } from '../components/info/info.component';
import { TruncatePipe } from '../../shared/pipes/truncate/truncate.pipe';
import { BypassSecurityPipe } from '../../shared/pipes/bypass-security/bypass-security.pipe';
import { GoogleAuthComponent } from '../components/google-auth/google-auth.component';
import { UsersComponent } from '../components/users/users.component';
import { InvitedUsersComponent } from '../components/invited-users/invited-users.component';
import { UserInviteComponent } from '../components/users/modals/user-invite/user-invite.component';
import { DashComponent } from '../components/dash/dash.component';
import { GdriveAuthComponent } from '../modals/gdrive-auth/gdrive-auth.component';

@NgModule({
  declarations: [
    // HeaderComponent,
    // FooterComponent,
    // MainLayoutComponent,
    LoaderComponent,
    AlertComponent,
    FilesizePipe,
    ToastComponent,
    InfoComponent,
    TruncatePipe,
    BypassSecurityPipe,
    GoogleAuthComponent,
    UsersComponent,
    InvitedUsersComponent,
    UserInviteComponent,
    DashComponent,
    GdriveAuthComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    BootstrapModule,
    NgbNavModule,
    NgbAlertModule,
    NgbTooltipModule,
    NgbProgressbarModule,
    NgbTypeaheadModule,
  ],
  exports: [
    // MainLayoutComponent
    ReactiveFormsModule,
    BootstrapModule,
    LoaderComponent,
    AlertComponent,
    FilesizePipe,
    NgbNavModule,
    NgbAlertModule,
    NgbTooltipModule,
    NgbProgressbarModule,
    NgbTypeaheadModule,
    ToastComponent,
    InfoComponent,
    TruncatePipe,
    BypassSecurityPipe,
    GoogleAuthComponent,
    UsersComponent,
    InvitedUsersComponent,
    UserInviteComponent,
    DashComponent,
    GdriveAuthComponent
  ]
})
export class SharedModule {
  constructor(config: NgbTooltipConfig) {
    // Global configuration, customize default values of tooltips used by this component tree
    config.container = 'body';
  }
}
