import { Injectable } from '@angular/core';
import { EncodeDecodeService } from '../encode-decode/encode-decode.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private THEME_LOCAL_STORAGE_KEY = 'theme';

  private GOOGLE_DRIVE_AUTH_CODE_SESSION_STORAGE_KEY = "GOOGLE_DRIVE_AUTH_CODE";

  private encodedGoogleDriveAuthCodeKey: string;

  constructor(private encodeDecodeService: EncodeDecodeService,) {
    this.encodedGoogleDriveAuthCodeKey = this.encodeDecodeService.encode(this.GOOGLE_DRIVE_AUTH_CODE_SESSION_STORAGE_KEY);
  }

  setTheme(theme = 'default') {
    localStorage.setItem(this.THEME_LOCAL_STORAGE_KEY, theme);
  }

  setGoogleDriveAuthCode(googleDriveAuthCode: string) {
    const encodedGoogleDriveAuthCode = this.encodeDecodeService.encode(googleDriveAuthCode);

    localStorage.setItem(this.encodedGoogleDriveAuthCodeKey, encodedGoogleDriveAuthCode);
  }

  getTheme() {
    return localStorage.getItem(this.THEME_LOCAL_STORAGE_KEY);
  }

  getGoogleDriveAuthCode() {
    const encodedGoogleDriveAuthCode = localStorage.getItem(this.encodedGoogleDriveAuthCodeKey);
    const decodedGoogleDriveAuthCode = this.encodeDecodeService.decode(encodedGoogleDriveAuthCode);

    return decodedGoogleDriveAuthCode;
  }
}
