import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UserAuthGuard } from '../app/core/guards/user/user-auth.guard';
import { AdminAuthGuard } from '../app/core/guards/admin/admin-auth.guard';
import { SuperAdminAuthGuard } from '../app/core/guards/super-admin/super-admin-auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/screens/home',
    pathMatch: 'full'
  },
  {
    path: 'screens',
    loadChildren: () => import('./modules/screens/screen.module').then(mod => mod.ScreenModule),
    canLoad: [UserAuthGuard]
  },
  {
    path: 'playlists',
    loadChildren: () => import('./modules/playlists/playlists.module').then(mod => mod.PlaylistsModule),
    canLoad: [UserAuthGuard]
  },
  {
    path: 'playlists/:id',
    loadChildren: () => import('./modules/playlists/playlists.module').then(mod => mod.PlaylistsModule),
    canLoad: [UserAuthGuard]
  },
  {
    path: 'content',
    loadChildren: () => import('./modules/content/content.module').then(mod => mod.ContentModule),
    canLoad: [UserAuthGuard]
  },
  {
    path: 'schedules',
    loadChildren: () => import('./modules/schedules/schedules.module').then(mod => mod.SchedulesModule),
    canLoad: [UserAuthGuard]
  },
  {
    path: 'links',
    loadChildren: () => import('./modules/links/links.module').then(mod => mod.LinksModule),
    canLoad: [UserAuthGuard]
  },
  {
    path: 'apps',
    loadChildren: () => import('./modules/apps/apps.module').then(mod => mod.AppsModule),
    canLoad: [UserAuthGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./modules/settings/settings.module').then(mod => mod.SettingsModule),
    canLoad: [UserAuthGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then(mod => mod.AdminModule),
    canLoad: [AdminAuthGuard]
  },
  {
    path: 'super-admin',
    loadChildren: () => import('./modules/super-admin/super-admin.module').then(mod => mod.SuperAdminModule),
    canLoad: [SuperAdminAuthGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/authentication/authentication.module').then(mod => mod.AuthenticationModule)
  },
  {
    // 403 - Forbidden, when user does not have sufficient access level
    path: 'forbidden',
    loadChildren: () => import('./modules/forbidden/forbidden-routing.module').then(mod => mod.ForbiddenRoutingModule)
  },
  {
    // 404 - Not Found. This needs to be the last item
    path: '**',
    loadChildren: () => import('./modules/page-not-found/page-not-found.module').then(mod => mod.PageNotFoundModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
