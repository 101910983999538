<nav class="navbar navbar-light bg-light py-0">
  <div class="navbar-brand mb-0 h1">
    <a (click)="toggleSidebar()" class="menu-toggle mr-4" [title]="isExpanded ? 'Collapse [' : 'Expand ['"
      (window:keydown.[)="toggleSidebar()">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
        class="feather feather-menu">
        <line x1="3" y1="12" x2="21" y2="12"></line>
        <line x1="3" y1="6" x2="21" y2="6"></line>
        <line x1="3" y1="18" x2="21" y2="18"></line>
      </svg>
    </a>
    <!-- Remove <div> and just use <a> if "glitch" effect is not needed -->
    <div class="just-for-glitch no-select" [ngStyle]="{'left':isExpanded ? '100px' : '74px'}">
      <a *ngIf="isExpanded" class="name glitch" data-glitch="Signogic" routerLink="/screens/home" routerLinkActive="active">Signogic</a>
      <a *ngIf="!isExpanded" class="name glitch" data-glitch="SC" routerLink="/screens/home" routerLinkActive="active">SC</a>
    </div>
  </div>
  
  <div *ngIf="loading" class="loader">
    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-clockwise rotating" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
      <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
    </svg>
  </div>

  <!-- Hidden on screens smaller than md -->
  <div *ngIf="currentMembership?.organisation_logo" class="ml-auto mr-3 d-none d-md-block">
    <img [src]="currentMembership.organisation_logo" class="org-logo">
  </div>

  <!-- <ul class="navbar-nav ml-auto mr-1">
    <li class="nav-item">
      <img [src]="currentMembership.organisation_logo" class="org-logo">
    </li>
  </ul> -->

  <ul class="navbar-nav">
    <!-- <li *ngIf="currentMembership?.organisation_logo" class="nav-item">
      <img [src]="currentMembership.organisation_logo" class="org-logo">
    </li> -->
    <li class="nav-item" ngbDropdown display="dynamic" placement="bottom-right">
      <a class="nav-link" ngbDropdownToggle id="navbarDropdown" role="button">
        <img *ngIf="currentUser?.picture" src="{{currentUser?.picture}}" width="32" height="32" class="rounded-circle mr-2">
        <svg *ngIf="!currentUser?.picture" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-person-circle signogic-colour" viewBox="0 0 16 16">
          <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
          <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
        </svg>
        <!-- <span class="d-none d-md-inline">{{currentUser.firstName}}</span> -->
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarDropdown3" class="dropdown-menu">
        <div class="dropdown-item info text-strong">
          <strong>{{currentUser?.name}}</strong>
        </div>
        <div class="dropdown-item info text-muted mb-3">
          <span>{{currentUser?.email}}</span>
        </div>
        <a class="dropdown-item" (click)="logOut()">Log out</a>
      </div>
    </li>
  </ul>
</nav>

<div [ngClass]="{'sidebar-minimised': !isExpanded}" [ngStyle]="{'height': sidebarHeight}" class="sidebar">
  <ul class="nav flex-column">
    <li *ngFor="let item of menuItems" class="nav-item">
      <a *ngIf="(item.minimumAccessLevel <= currentMembership.access_level) && (item.allowedRoleTypes.includes(currentMembership.type))"
        class="nav-link" [routerLink]="item.link" routerLinkActive="active" title="{{ item.title }}">
        <span [innerHTML]="item.icon"></span>
        <span *ngIf="isExpanded" class="title">{{ item.title }}</span>
        <!-- <span *ngIf="isExpanded" class="ml-3 badge badge-secondary">3</span> -->
      </a>
    </li>
  </ul>
</div>