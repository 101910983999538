import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { takeUntil, ReplaySubject, Observable, startWith, map, firstValueFrom } from "rxjs";

import { LoggerService } from "../../../core/services/logger/logger.service";
import { environment } from '../../../../environments/environment';
import { MembershipService } from "../../../core/services/membership/membership.service";
import { ToastService } from "../../../core/services/toast/toast.service";
import { User } from "../../interfaces/user";
import { UsersService } from 'src/app/core/services/users/users.service';

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
})
export class UsersComponent implements OnInit {
  public appName: string;

  private destroyed: ReplaySubject<boolean> = new ReplaySubject(1);

  public users: User[];

  public users$: Observable<User[]>;

  public filter = new FormControl("");

  constructor(
    private loggerService: LoggerService,
    private membershipService: MembershipService,
    private usersService: UsersService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  private init() {
    try {
      this.appName = environment.appName;

      this.getUsers();
    } catch (error) {
      this.loggerService.error(
        'Error in running "init()" from users component',
        error
      );
      return error;
    }
  }

  private getUsers() {
    try {
      this.membershipService
        .getMembershipsWithUsers()
        .pipe(takeUntil(this.destroyed))
        .subscribe((response) => {
          if (response.status === 200) {
            const responseBody: any = response.body;

            if (responseBody.data.length > 0) {
              this.users = responseBody.data;

              this.filterUsers();
            } else {
              // No users
              this.users = [];
            }
            // if (!responseBody.error) {
            //   this.toastService.show(responseBody.message, {
            //     classname: "bg-success text-light",
            //     delay: 3000,
            //   });
            // } else {
            //   this.toastService.show(responseBody.message, {
            //     classname: "bg-danger text-light",
            //     delay: 3000,
            //   });
            // }
          } else {
            this.loggerService.error("Error", response.statusText);
          }
        });
    } catch (error) {
      this.loggerService.error(
        'Error in running "getUsers()" from users component',
        error
      );
      return error;
    }
  }

  private searchUsers(text: string): User[] {
    try {
      return this.users.filter((user) => {
        const term = text.toLowerCase();
        return (
          user.first_name.toLowerCase().includes(term) ||
          user.last_name.toLowerCase().includes(term) ||
          user.email.toLowerCase().includes(term) ||
          user.role_name.toLowerCase().includes(term)
        );
      });
    } catch (error) {
      this.loggerService.error(
        'Error in running "searchUsers()" from users component',
        error
      );
      return error;
    }
  }

  private filterUsers() {
    try {
      this.users$ = this.filter.valueChanges.pipe(
        startWith(""),
        map((text: string) => this.searchUsers(text))
      );
    } catch (error) {
      this.loggerService.error(
        'Error in running "filterUsers()" from users component',
        error
      );
      return error;
    }
  }

  public async deleteUser(userId: number) {
    try {
      if (confirm("Are you sure you want to delete this user?")) {
        console.log('userId', userId)

        const deleteUserResponse = await firstValueFrom(this.usersService.delete(userId));
        if (deleteUserResponse.status === 200) {
          const responseBody: any = deleteUserResponse.body;
          if (!responseBody.error) {
            this.toastService.show(responseBody.message, { classname: 'bg-success text-light', delay: 3000 });

            this.getUsers();
          } else {
            this.toastService.show(responseBody.message, { classname: 'bg-danger text-light', delay: 3000 });
          }
        } else {
          console.error('Error', deleteUserResponse.statusText);
        }
      }
    } catch (error) {
      this.loggerService.error(
        'Error in running "deleteUser()" from users component',
        error
      );
      return error;
    }
  }
}
