import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  Route,
  CanActivateChild,
  CanLoad,
} from "@angular/router";
import { Observable } from "rxjs";

import { UserService } from "../../services/user/user.service";
import { MembershipService } from "../../../core/services/membership/membership.service";

@Injectable({
  providedIn: "root",
})
export class AdminAuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private userService: UserService,
    private membershipService: MembershipService,
    private router: Router
  ) {}

  // @TODO
  // Should we get route and based on route check access level?
  // Another option is create separate guards?
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.checkAccess();
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): Promise<boolean> {
    const url = `/${route.path}`;

    return this.checkAccess();
  }

  private async checkAccess() {
    try {
      // If not signed in, no need for additional checks
      if (!this.userService.isSignedIn()) {
        // Navigate to the home page with extras
        // Home will redirect user to auth page, rather than navigating
        // to auth from here I think it is better to handle that to "user-auth" guard.
        this.router.navigate(["/"]);
        return false;
      }

      // Resetting User details, if user changed session storage data
      // this will reset it to the data from API.
      await this.userService.resetUserDetails();

      const currentMembership: any =
        await this.membershipService.getCurrentMembership();

      const currentUserAccessLevel = currentMembership.access_level;
      // Admin access level is 5 = Account Administrator
      if (currentUserAccessLevel >= 5) {
        return true;
      }

      // Navigate to forbidden page with extras
      this.router.navigate(["/forbidden"]);
      return false;
    } catch (error) {
      console.error(error);
      // Navigate to the home page with extras
      this.router.navigate(["/"]);
      return false;
    }
  }
}
