import { Injectable } from "@angular/core";

import { environment } from "../../../../environments/environment";
import { ScriptLoadingService } from "../script-loading/script-loading.service";
// import { UserService } from "../user/user.service";
import { LoggerService } from "../logger/logger.service";

// declare const gapi: any;

@Injectable({
  providedIn: "root",
})
export class GoogleAuthService {

  private google;

  // public auth2: any;

  constructor(
    private loggerService: LoggerService,
    private scriptLoadingService: ScriptLoadingService,
    // private userService: UserService
  ) { }

  public async init() {
    try {
      // return new Promise((resolve, reject) => {
      // this.scriptLoadingService
      //   .load("google-sign-in2")
      //   .then((response) => {
      //     // Script loaded successfully
      //     // console.log('Script loaded successfully', response);
      //     // After successfully script loading initialising auth
      //     this.googleAuthInit();
      //     // resolve(response);
      //   })
      //   .catch((error) => {
      //     console.error("Script loading error.", error);
      //     // reject(error);
      //   });
      // });

      // Google Identity Services SDK for Web
      const scriptLoadResponse = await this.scriptLoadingService.load(
        "googleIdentityServicesSDK"
      );

      if (scriptLoadResponse[0].loaded) {
        // Script successfully loaded
        this.initGoogleSignInSDK();
      }
    } catch (error) {
      this.loggerService.error(
        'Error in running "initService()" from google auth service',
        error
      );
      return error;
    }
  }

  // More info - https://developers.google.com/identity/gsi/web/tools/configurator
  private initGoogleSignInSDK() {
    try {
      this.google = window['google'];

      this.google.accounts.id.initialize({
        client_id: environment.googleSignInClientId,
        ux_mode: "redirect",
        login_uri: environment.apiUrl + '/login/google/verify-token',
      });

      this.google.accounts.id.renderButton(
        document.getElementById("googleAuthButtonDiv"),
        {
          theme: "filled_blue",
          shape: 'rectangular',
          text: 'continue_with',
          size: "large",
          width: '400',
        }  // customization attributes
      );
    } catch (error) {
      this.loggerService.error(
        'Error in running "initGoogleSignInSDK()" from google auth service',
        error
      );
      return error;
    }
  }

  // More info - https://developers.google.com/identity/protocols/oauth2/scopes#google-sign-in
  // googleAuthInit() {
  //   gapi.load("auth2", () => {
  //     this.auth2 = gapi.auth2.init({
  //       client_id: environment.googleSignInClientId,
  //       cookie_policy: "single_host_origin",
  //       scope: "profile email",
  //       ux_mode: "redirect",
  //       redirect_uri: environment.googleAuthRedirectURI,
  //     });
  //     // May be a better approach is there?
  //     this.renderButton(document.getElementById("gSignIn2"));
  //   });
  // }

  // renderButton(element: HTMLElement) {
  //   gapi.signin2.render(element, {
  //     scope: "profile email",
  //     width: 240,
  //     height: 40,
  //     longtitle: true,
  //     theme: "dark",
  //     onsuccess: (googleUser) => {
  //       this.loginSuccess(googleUser);
  //     },
  //     onfailure: (error) => {
  //       console.error("Google sign in error.", error);
  //     },
  //   });
  // }

  // loginSuccess(credential: GoogleCredentialResponse) {
  //   console.log('credential', credential);
  //   // this.userService.googleLogInSuccessHandler(googleUser);
  // }

  public logOut(): Promise<any> {
    return new Promise((resolve, reject) => {
      // const auth2 = gapi.auth2.getAuthInstance();
      // auth2
      //   .signOut()
      //   .then(() => {
      //     resolve(true);
      //   })
      //   .catch((error) => {
      //     console.error("Google sign out error", error);
      //     reject(error);
      //   });
    });
  }
}
