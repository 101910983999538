import { Component } from '@angular/core';

import { environment } from '../environments/environment';
import { LoggerService } from '../app/core/services/logger/logger.service';
import { AnalyticsService } from '../app/core/services/analytics/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'HL Signage';

  constructor(
    private loggerService: LoggerService,
    private analyticsService: AnalyticsService
  ) {
    this.initializeApp();
  }

  private initializeApp() {
    try {
      this.analyticsService.startTrackerWithId(environment.googleAnalyticsId);
    } catch (error) {
      this.loggerService.error('Error in initialising app', error);
      return error;
    }
  }
}
