import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'bypassSecurity'
})
export class BypassSecurityPipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer
  ) { }
  
  transform(url: string): SafeResourceUrl {
    const trustedResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);

    return trustedResourceUrl;
  }

}
