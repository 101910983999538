import { Injectable } from "@angular/core";

import { LoggerService } from "../logger/logger.service";
import { EncodeDecodeService } from '../encode-decode/encode-decode.service';
import { WebCryptoService } from "../web-crypto/web-crypto.service";

@Injectable({
  providedIn: "root",
})
export class SessionStorageService {
  private TOKEN_SESSION_STORAGE_KEY = "ACCESS_TOKEN";

  private encodedTokenKey: string;

  private USER_SESSION_STORAGE_KEY = "CURRENT_USER";

  private MEMBERSHIPS_SESSION_STORAGE_KEY = "MEMBERSHIPS";

  private encodedUserKey: string;

  private encodedMembershipKey: string;

  // @TODO
  // Do we need this?
  // If membership Id is changing only from settings/just one place
  // then we may not need to observe this.
  // sessionStorageSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private loggerService: LoggerService,
    private encodeDecodeService: EncodeDecodeService,
    private webCryptoService: WebCryptoService
  ) {
    // @TODO
    // Implement encryption, code to encrypt and decrypt is done,
    // need to implement from here.
    // const tmp = {
    //   id: "7f85f6db-7894-418d-996c-f3f4ac61bf8e",
    //   sellerScore: 80,
    //   salesCount: 5,
    // };
    // console.log(new Date().getMilliseconds());
    // this.webCryptoService.initEncrypt(tmp);
    // console.log(new Date().getMilliseconds());

    this.encodedTokenKey = this.encodeDecodeService.encode(this.TOKEN_SESSION_STORAGE_KEY);

    this.encodedUserKey = this.encodeDecodeService.encode(this.USER_SESSION_STORAGE_KEY);

    this.encodedMembershipKey = this.encodeDecodeService.encode(this.MEMBERSHIPS_SESSION_STORAGE_KEY);
  }

  setToken(token: string) {
    // This is api token
    sessionStorage.setItem(this.encodedTokenKey, token);
  }

  setUser(user: Record<string, unknown>) {
    const encodedUser = this.encodeDecodeService.encode(user);

    sessionStorage.setItem(this.encodedUserKey, encodedUser);
  }

  setMemberships(memberships: any) {
    const encodedMemberships = this.encodeDecodeService.encode(memberships);

    sessionStorage.setItem(this.encodedMembershipKey, encodedMemberships);
  }

  getToken() {
    return sessionStorage.getItem(this.encodedTokenKey);
  }

  getUser() {
    const encodedUser = sessionStorage.getItem(this.encodedUserKey);
    const decodedUser = this.encodeDecodeService.decode(encodedUser, true);

    return decodedUser;
  }

  getMemberships() {
    const encodedMemberships = sessionStorage.getItem(this.encodedMembershipKey);
    const decodedMemberships = this.encodeDecodeService.decode(encodedMemberships, true);

    return decodedMemberships;
  }

  setItem(key: string, value: string) {
    const encodedKey = this.encodeDecodeService.encode(key);

    const encodedValue = this.encodeDecodeService.encode(value);

    sessionStorage.setItem(encodedKey, encodedValue);
  }

  getItem(key: string, object = false) {
    const encodedKey = this.encodeDecodeService.encode(key);

    const encodedString = sessionStorage.getItem(encodedKey);

    const decodedValue = this.encodeDecodeService.decode(encodedString, object);

    return decodedValue;
  }

  removeItem(key: string) {
    sessionStorage.removeItem(key);
  }
}
