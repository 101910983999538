import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { UserService } from '../../core/services/user/user.service';

/**
 * Auto logout if 401 response returned from API.
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private userService: UserService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // Auto logout if 401 response returned from api
                this.userService.signOut();
            }

            const errorMessage = err.error.message || err.statusText;
            // return throwError(error);
            return throwError(() => new Error(errorMessage))
        }));
    }

}