import { Component, Input, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GdriveAuthService } from '../../../core/services/gdrive-auth/gdrive-auth.service';
import { GDriveSyncService } from '../../../core/services/g-drive-sync/g-drive-sync.service';
import { DrivesService } from '../../../core/services/drives/drives.service';
import { environment } from '../../../../environments/environment';
// import { ContentHomeComponent } from '../../../modules/content/pages/content-home/content-home.component';
import { SessionStorageService } from '../../../core/services/session-storage/session-storage.service';
import { LoggerService } from 'src/app/core/services/logger/logger.service';
import { LocalStorageService } from 'src/app/core/services/local-storage/local-storage.service';

@Component({
  selector: 'app-gdrive-auth',
  templateUrl: './gdrive-auth.component.html',
  styleUrls: ['./gdrive-auth.component.scss']
})
export class GdriveAuthComponent implements OnInit {

  // closeResult: string;
  appName: string;
  authUrl: string = null;
  gDriveConnectForm: FormGroup;
  gDriveSyncForm: FormGroup;
  error = false;
  message: string;
  driveSynchronisationId: number;
  gDriveConnectCompleted = false;
  parentFolderValidationCompleted = false;
  driveSynchronisationInProgress = false;
  driveSynchronisationCompleted = false;
  public currentActiveTabId = 1;
  public connectToGoogleDriveClicked = false;
  public readyForStageTwo = false;
  @Input() reconnect = false;
  private responseData;
  private googleDriveAuthCodeIntervalHandle: null | ReturnType<typeof setTimeout>;
  public googleDriveAuthCode: string;

  constructor(
    private modalService: NgbModal,
    private gDriveAuthService: GdriveAuthService,
    private gDriveSyncService: GDriveSyncService,
    private drivesService: DrivesService,
    private fb: FormBuilder,
    // private contentHomeComponent: ContentHomeComponent,
    private sessionStorageService: SessionStorageService,
    private localStorageService: LocalStorageService,
    private loggerService: LoggerService
  ) { }

  ngOnInit() {
    this.appName = environment.appName;
    this.generateForms();
  }

  generateForms() {
    // this.gDriveConnectForm = this.fb.group({
    //   authCode: ['', [Validators.required, Validators.minLength(15)]]
    // });
    this.gDriveSyncForm = this.fb.group({
      sharedDriveId: ['', [Validators.required, Validators.minLength(15)]]
    });
  }

  /**
   * Show drive connect modal
   */
  public gDriveAuth() {
    this.gDriveAuthService.getAuthUrl().subscribe((response: any) => {
      const url = response.body.url;
      this.authUrl = url;
      // window.open(url, '_blank');
    });
  }

  public connectToGoogleDrive() {
    // Added delay to prevent tab change before auth url is open in new tab.
    setTimeout(() => {
      this.connectToGoogleDriveClicked = true;
      this.currentActiveTabId = (this.reconnect) ? 1 : 2;
    }, 100);

    this.googleDriveAuthCodeIntervalHandle = setInterval(() => {
      this.googleDriveAuthCode = this.localStorageService.getGoogleDriveAuthCode();
      // if (this.reconnect) {
        this.generateToken();
      // }
    }, 1000);
  }

  handleAuthResponse(response, step: number) {
    this.error = response.body.error;
    if (this.error) {
      this.message = response.body.message;
    }

    if (step == 2) {
      if (this.error) {
        this.gDriveConnectCompleted = false;
      } else {
        this.gDriveConnectCompleted = true;
        this.readyForStageTwo = true;
        // Switching to next tab
        this.currentActiveTabId = 2;
      }
    } else if (step == 3) {
        this.driveSynchronisationId = response.body.data.drive_synchronisation_id;

        if (this.driveSynchronisationId) {
          // Run drive sync
          this.runDriveSynchronisation();
        } else {
          console.error('No drive ID, unable to perform drive synchronisation.');
        }
    } else {
      // Step 4 - last step
      this.driveSynchronisationCompleted = (this.error) ? false : true;

      if (response.body.data?.drive_id) {
        // Add newly connected drive to session storage
        this.sessionStorageService.setItem('driveId', response.body.data.drive_id);
      }

      // Mark as drive auth complete
      this.drivesService.setCurrentDriveAuthComplete('true');
    }
  }

  generateToken() {
    clearInterval(this.googleDriveAuthCodeIntervalHandle);
    // TODO: Use EventEmitter with form value
    this.gDriveAuthService.getToken(this.googleDriveAuthCode)
      .subscribe(
        response => {
          if (!this.reconnect) {
            // Initial drive connect
            this.handleAuthResponse(response, 2)
          } else {
            // Reconnect existing drive
            // INFO:- getToken() updates existing token
            this.handleAuthResponse(response, 4)
          }
        },
        error => console.error('Error', error)
      );
  }

  validateParentFolder() {
    this.gDriveAuthService.validateSharedDrive(this.gDriveSyncForm.value.sharedDriveId)
      .subscribe(
        response => this.handleAuthResponse(response, 3),
        error => console.error('Error', error)
      );
  }

  private runDriveSynchronisation() {
    this.driveSynchronisationInProgress = true;

    this.gDriveSyncService.initiateSynchronisation(this.driveSynchronisationId)
      .subscribe(
        response => this.handleAuthResponse(response, 4),
        error => console.error('Error', error),
        () => {
          this.driveSynchronisationInProgress = false;
        }
      );
  }

  open(content) {
    // Generate Auth Url
    this.gDriveAuth();
    // Reset form
    this.gDriveSyncForm.reset();
    // Clear error
    this.error = false;

    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
      this.reInitHomeComponent();
    }, (reason) => {
      this.reInitHomeComponent();
    });
  }

  private reInitHomeComponent() {
    if (this.driveSynchronisationCompleted) {
      // this.contentHomeComponent.init();
    }
  }

  public reConnectDrive() {
    try {
      // Reconnect existing Drive
      console.info('Reconnect GDrive in progress...');
      console.log('response', this.responseData);
      // this.gDriveAuthService.updateToken()
    } catch (error) {
      this.loggerService.error('Error in re connecting Google Drive', error);
    }
  }
}
