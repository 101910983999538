import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  // https://stackoverflow.com/questions/44669340/how-to-truncate-text-in-angular2
  transform(value: string, limit: number = 30, completeWords: boolean = false, ellipsis: string = '...'): string {
    let result: string = value || '';

    if (value && (value.length > limit)) {
      if (completeWords) {
        // https://stackoverflow.com/questions/18679576/counting-words-in-string
        // https://stackoverflow.com/questions/6543917/count-number-of-words-in-string-using-javascript
        const numberOfWords: number = value.trim().split(/\s+/).length;

        if (numberOfWords > 1) {
          limit = value.substr(0, limit).lastIndexOf(' ');
        }
      }

      result = value.length > limit ? value.substr(0, limit) + ellipsis : value;
    }

    return result;
  }

}
