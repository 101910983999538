import { Injectable } from '@angular/core';

import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/shared/interfaces/api-response';
import { SessionStorageService } from '../session-storage/session-storage.service';
import { Drive } from '../../../shared/interfaces/drive';

@Injectable({
  providedIn: 'root'
})
export class DrivesService {
  private driveSharedDriveIdKey = 'DRIVE_SHARED_DRIVE_ID';

  constructor(
    private httpClient: HttpClient,
    private sessionStorageService: SessionStorageService
    ) { }

  public getDrive(organisationId: number): Observable<HttpResponse<ApiResponse>> {
    const params = new HttpParams().append('organisationId', organisationId.toString());
    return this.httpClient.get<ApiResponse>('/drives/get', {
      params,
      observe: 'response'
    });
  }

  public setCurrentDriveDetails(drive: Drive) {
    if (drive.drive_id) {
      this.sessionStorageService.setItem('driveId', drive.drive_id.toString());
      this.sessionStorageService.setItem('driveProviderId', drive.drive_provider_id.toString());

      if (drive.drive_provider_id === 2) {
        // GDrive
        this.setCurrentDriveAuthComplete(drive.authComplete.toString());

        this.setDriveSharedDriveId(drive.shared_drive_id);
      }
    } else {
      this.sessionStorageService.setItem('driveId', '0');
    }
  }

  public getCurrentDriveId() {
    return Number(this.sessionStorageService.getItem('driveId'));
  }

  public getCurrentDriveProviderId() {
    return Number(this.sessionStorageService.getItem('driveProviderId'));
  }

  public setCurrentDriveAuthComplete(status: string) {
    this.sessionStorageService.setItem('driveAuthComplete', status);
  }

  public getCurrentDriveAuthComplete() {
    const authComplete = (this.sessionStorageService.getItem('driveAuthComplete') === 'true');
    return authComplete;
  }

  public setDriveSharedDriveId(sharedDriveId: string): void {
    this.sessionStorageService.setItem(this.driveSharedDriveIdKey, sharedDriveId);
  }

  public getDriveSharedDriveId(): string | null {
    const encryptedSharedDriveId = this.sessionStorageService.getItem(this.driveSharedDriveIdKey);
    if (encryptedSharedDriveId) {
      return atob(encryptedSharedDriveId);
    } else {
      return null;
    }
  }

}
