/**
 * @author Jyothish Mathew
 * 
 * Importing individual modules from ng-bootstrap
 * https://ng-bootstrap.github.io/#/getting-started
 */

import { NgModule } from '@angular/core';

import { NgbModalModule, NgbDropdownModule, NgbAlertModule, NgbToastModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [],
  imports: [
    NgbModalModule,
    NgbDropdownModule,
    NgbAlertModule,
    NgbToastModule,
    NgbTimepickerModule
  ],
  exports: [
    NgbModalModule,
    NgbDropdownModule,
    NgbAlertModule,
    NgbToastModule,
    NgbTimepickerModule
  ]
})
export class BootstrapModule { }
