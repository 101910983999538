import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SessionStorageService } from '../services/session-storage/session-storage.service';
import { environment } from '../../../environments/environment';

/**
 * Adding token to all api requests.
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private sessionStorageService: SessionStorageService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.sessionStorageService.getToken();
        const apiUrl = environment.apiUrl;
        const isApiUrl = request.url.startsWith(apiUrl);

        if (token && isApiUrl) {
            const updatedRequest = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
            return next.handle(updatedRequest);
        } else {
            return next.handle(request);
        }
    }
}