import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  // constructor() { }

    // @TODO
  // Expand
  // https://www.codemag.com/Article/1711021/Logging-in-Angular-Applications
  // https://medium.com/@dneimke/configurable-logging-in-angular-projects-30dd5d999db6
  public log(message: string, ...optionalParams: any[]) {
    console.log(message, optionalParams);
  }

  public warn(message: string, ...optionalParams: any[]) {
    console.warn(message, optionalParams);
  }

  public error(message: string, ...optionalParams: any[]) {
    console.error(message, optionalParams);
  }
}
