<main>
  <div class="container-fluid">
    <div *ngIf="!users" class="loader">
      <app-loader [text]="'Loading...'"></app-loader>
    </div>

    <div *ngIf="users && users?.length < 1" class="no-users">
      <div class="row">
        <div class="col text-center">
          <h3>No Users</h3>
          <div class="text-muted">Please contact <span class="text-capitalize">{{ appName }}</span> support.</div>
        </div>
      </div>
      <div class="row">
        <div class="col-4 offset-4 mt-5 mb-3">
          <img src="assets/svg/add-user.svg" class="img-fluid" />
        </div>
      </div>
    </div>

    <div *ngIf="users && users?.length >= 1" class="row pt-3 pb-2 mb-3">
      <div class="col">
        <form class="float-left">
          <div class="form-group form-inline">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path
                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
              />
            </svg>
            <input
              class="form-control ml-2"
              type="text"
              placeholder="Search..."
              [formControl]="filter"
            />
          </div>
        </form>

        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">First Name</th>
              <th scope="col">Last Name</th>
              <th scope="col">Email</th>
              <th scope="col">Role</th>
              <th scope="col">Status</th>
              <th scope="col">Last Login</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let user of users$ | async; let i = index"
              class="user-row"
            >
              <th scope="row">{{ i + 1 }}</th>
              <td>
                <ngb-highlight
                  [result]="user.first_name"
                  [term]="filter.value"
                ></ngb-highlight>
              </td>
              <td>
                <ngb-highlight
                  [result]="user.last_name"
                  [term]="filter.value"
                ></ngb-highlight>
              </td>
              <td class="user-email">
                <a [href]="'mailto:' + user.email">
                  <ngb-highlight
                    [result]="user.email"
                    [term]="filter.value"
                  ></ngb-highlight>
                  <img
                    class="ml-2 icon-sm user-email-icon"
                    src="assets/icons/envelope-open.svg"
                  />
                </a>
              </td>
              <td>
                <ngb-highlight
                  [result]="user.role_name"
                  [term]="filter.value"
                ></ngb-highlight>
              </td>
              <td>
                <span *ngIf="user.active" class="text-success">Active</span>
                <span *ngIf="!user.active" class="text-warning">Inactive</span>
              </td>
              <td>
                <div *ngIf="user?.last_login_date" ngbTooltip="Last log in was on {{ user.last_login_date | date: 'dd MMMM yyyy @ HH:mm:ss' }}">
                  <div *ngIf="user?.days_passed_since_last_login === 0; then lastLoginWasToday else lastLoginWasNotToday">
                  </div>
                  <ng-template #lastLoginWasToday>Today</ng-template>
                  <ng-template #lastLoginWasNotToday>{{ user?.days_passed_since_last_login }}&nbsp;day(s) ago</ng-template>
                </div>

                <div *ngIf="!user?.last_login_date">
                  <app-dash></app-dash>
                </div>
              </td>
              <td>
                <button
                  type="button"
                  class="btn btn-outline-danger"
                  (click)="deleteUser(user.user_id)"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</main>
