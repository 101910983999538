import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { OrganisationsService } from '../services/organisations/organisations.service';
import { environment } from '../../../environments/environment';

/**
 * Adding parameters to all api requests where method is "POST".
 */
@Injectable()
export class AddingParametersInterceptor implements HttpInterceptor {
    constructor(private organisationsService: OrganisationsService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const organisationId = this.organisationsService.getCurrentOrganisationId();
        const apiUrl = environment.apiUrl;
        const isApiUrl = request.url.startsWith(apiUrl);

        // Excluding "GET" requests as we don't want to inject anything to response body.
        // Also we don't record "GET" requests, so no need for extra parameters there.
        if ((request.method.match('POST')) && organisationId && isApiUrl) {
            const updatedRequest = request.clone({
                body: {
                    ...request.body,
                    organisationId: organisationId
                }
            });
            return next.handle(updatedRequest);
        } else {
            return next.handle(request);
        }
    }
}