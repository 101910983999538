import { Injectable } from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '../services/loader/loader.service'

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  private requests: HttpRequest<any>[] = [];

  constructor(private loaderService: LoaderService) { }

  removeRequest(request: HttpRequest<any>) {
    const i = this.requests.indexOf(request);

    if (i >= 0) {
      this.requests.splice(i, 1);
    }

    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    this.requests.push(request);

    this.loaderService.isLoading.next(true);
    return new Observable(observer => {
      const subscription = next.handle(request)
        .subscribe({
          next: value => {
            if (value instanceof HttpResponse) {
              this.removeRequest(request);
              observer.next(value);
            }
          },
          error: err => {
            alert('Error:' + JSON.stringify(err));
            this.removeRequest(request);
            observer.error(err);
          },
          complete: () => {
            this.removeRequest(request);
            observer.complete();
          }
        });
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(request);
        subscription.unsubscribe();
      };
    });
    // return next.handle(request);
  }
}
