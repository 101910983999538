<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Google Drive
      <span *ngIf="!reconnect">Sync</span>
      <span *ngIf="reconnect">Reconnect</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div id="gDriveSync" class="modal-body">
    <ul ngbNav #nav="ngbNav" [(activeId)]="currentActiveTabId" class="nav-tabs">
      <!-- Step 1 - Connect to Google Drive -->
      <li [ngbNavItem]="1" [disabled]="driveSynchronisationInProgress || driveSynchronisationCompleted">
        <a ngbNavLink>One</a>
        <ng-template ngbNavContent>
          <div *ngIf="!driveSynchronisationCompleted" class="text-center">
            <a [href]="authUrl" target="_blank" ngbAutofocus (click)="connectToGoogleDrive()"
              class="btn btn-outline-info mt-3 mb-3" role="button">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-external-link">
                <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                <polyline points="15 3 21 3 21 9"></polyline>
                <line x1="10" y1="14" x2="21" y2="3"></line>
              </svg>
              <span>Connect to Google Drive</span>
            </a>
            <div class="pt-3">
              <p class="text-muted text-left">
                Click above button and we'll open a new page to help you connect your Google Drive account. When you are
                authorised
                and approved {{ appName }}, proceed to next step.
              </p>
            </div>
          </div>
        </ng-template>
      </li>

      <!-- Step 2 - Authorise Code -->
      <!-- <li [ngbNavItem]="2" [disabled]="!connectToGoogleDriveClicked || driveSynchronisationInProgress || driveSynchronisationCompleted">
        <a ngbNavLink>Two</a>
        <ng-template ngbNavContent>
          <div *ngIf="!driveSynchronisationCompleted">
            <div class="pt-3">
              <p class="text-muted text-left">
                Paste your authorisation code bellow and click 'Connect GDrive'.
              </p>
            </div>
            <form [formGroup]="gDriveConnectForm" (ngSubmit)="generateToken()">
              <div class="form-group">
                <label for="authCode">Authorisation Code:</label>
                <input formControlName="authCode" type="text" class="form-control" id="authCode"
                  aria-describedby="authCode" placeholder="Code copied from Google sign in page" autocomplete="off">
              </div>
            </form>
          </div>
        </ng-template>
      </li> -->

      <!-- Step 2 - Get Parent Folder/drive ID, if valid shared drive initiate sync -->
      <li *ngIf="!reconnect" [ngbNavItem]="2" [disabled]="!readyForStageTwo">
        <a ngbNavLink>Two</a>
        <ng-template ngbNavContent>
          <div *ngIf="!driveSynchronisationInProgress && !driveSynchronisationCompleted">
            <div class="pt-3">
              <div class="text-muted text-left">
                <p>Please tell us which shared drive in your Google Drive you want to sync with {{ appName }}.</p>
                <p>Getting this shared drive ID is easy, navigate to <a href="https://drive.google.com/drive/shared-drives"
                    target="_blank">https://drive.google.com/drive/shared-drives</a> and make a new shared drive or select an existing one. After you did this, open up the
                  shared drive and check the URL:</p>
                <P>
                  <img src="assets/gdrive/gdrive-folder-id.png" alt="gdrive-folder-id" class="img-thumbnail">
                </P>
                <p>Copy this ID and paste it bellow, then click 'Enable Sync'.</p>
              </div>
            </div>
            <form [formGroup]="gDriveSyncForm" (ngSubmit)="validateParentFolder()">
              <div class="form-group">
                <label for="sharedDriveId">Shared Drive ID:</label>
                <input formControlName="sharedDriveId" type="text" class="form-control" id="sharedDriveId"
                  aria-describedby="sharedDriveId" placeholder="ID copied from URL">
              </div>
            </form>
          </div>
        </ng-template>
      </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>

    <div *ngIf="error && !driveSynchronisationCompleted">
      <ngb-alert [type]="'danger'" [dismissible]="false">
        <strong>Error!</strong> {{ message }}
      </ngb-alert>
    </div>
    <div *ngIf="driveSynchronisationId && !error && !driveSynchronisationCompleted" class="authorised">
      <div class="text-center">
        <svg class="icon-cloud-sync mb-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 317.17 317.17">
          <path fill="#2a2a31"
            d="M260.153 107.248c.012-.524.018-1.042.018-1.555 0-46.565-37.884-84.449-84.448-84.449-44.264 0-80.683 34.232-84.175 77.612a66.167 66.167 0 0 0-25.145-4.938C29.788 93.918 0 123.707 0 160.322s29.788 66.404 66.403 66.404c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5C38.06 211.727 15 188.667 15 160.322s23.06-51.404 51.403-51.404a51.137 51.137 0 0 1 28.489 8.628 7.5 7.5 0 0 0 11.645-6.777l-.038-.534c-.116-1.593-.226-3.098-.226-4.542 0-38.295 31.155-69.449 69.449-69.449s69.448 31.154 69.448 69.449c0 2.504-.18 5.227-.55 8.326a7.497 7.497 0 0 0 8.131 8.357c1.602-.146 2.927-.215 4.171-.215 24.949 0 45.247 20.298 45.247 45.247 0 24.95-20.298 45.248-45.247 45.248-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5c33.221 0 60.247-27.027 60.247-60.248.001-32.137-25.29-58.477-57.016-60.16z" />
          <path fill="#2a2a31"
            d="M161.308 144.389c-41.779 0-75.769 33.989-75.769 75.769s33.989 75.769 75.769 75.769 75.769-33.989 75.769-75.769-33.99-75.769-75.769-75.769zm0 136.537c-33.508 0-60.769-27.261-60.769-60.769s27.261-60.769 60.769-60.769 60.769 27.261 60.769 60.769-27.262 60.769-60.769 60.769z" />
          <path class="sync" fill="#ea3970"
            d="M150.552 210.909a7.5 7.5 0 0 0 8.111-6.834c.345-4.033-2.572-7.583-6.555-8.073 8.69-3.323 18.896-1.843 26.349 4.724a7.5 7.5 0 0 0 9.916-11.256c-12.922-11.383-31.078-13.093-45.566-5.719a7.5 7.5 0 0 0-6.808-6.574 7.483 7.483 0 0 0-8.111 6.834l-1.479 17.309a7.498 7.498 0 0 0 6.834 8.111l17.309 1.478zM189.402 230.443l-17.319-1.354c-4.125-.317-7.739 2.763-8.063 6.893-.316 4.047 2.644 7.585 6.647 8.031a25.894 25.894 0 0 1-7.657 1.726c-6.882.489-13.548-1.74-18.76-6.267a7.501 7.501 0 0 0-9.836 11.326c7.496 6.509 16.889 10.007 26.719 10.007.974 0 1.951-.034 2.932-.104a40.739 40.739 0 0 0 15.96-4.486 7.502 7.502 0 0 0 7.444 6.524 7.5 7.5 0 0 0 7.47-6.915l1.355-17.318a7.503 7.503 0 0 0-6.892-8.063z" />
        </svg>
        <span>Google Drive authorisation completed successfully. Synchronisation is currently in progress, please
          wait...</span>
      </div>
    </div>
    <div *ngIf="driveSynchronisationCompleted" class="completed">
      <div class="text-center">
        <svg class="icon-check mb-3" viewBox="0 0 225 225" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"
          style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;">
          <g transform="matrix(1,0,0,1,-178.667,-170.667)">
            <g class="check" transform="matrix(1,0,0,1,176,113)">
              <path d="M65,166L101,202L165,138" style="fill:none;stroke:#28a745;stroke-width:16.67px;" />
            </g>
            <circle class="circle" cx="291" cy="283" r="104" style="fill:none;stroke:#2a2a31;stroke-width:16.67px;" />
          </g>
        </svg>
        <span *ngIf="!reconnect">Google Drive synchronisation completed successfully.</span>

        <span *ngIf="reconnect">Google Drive reconnection completed successfully.</span>

        <span>&nbsp;You can safely close this popup.</span>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="modal.close('Cancel click')">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
        class="feather feather-x-circle">
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="15" y1="9" x2="9" y2="15"></line>
        <line x1="9" y1="9" x2="15" y2="15"></line>
      </svg>
      <span class="text align-middle">{{ (driveSynchronisationCompleted) ? 'Close' : 'Cancel' }}</span>
    </button>

    <!-- <button *ngIf="!gDriveConnectCompleted" [disabled]="!googleDriveAuthCode || driveSynchronisationCompleted"
      (click)="generateToken()" type="button" class="btn btn-outline-success">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
        class="feather feather-cloud-lightning">
        <path d="M19 16.9A5 5 0 0 0 18 7h-1.26a8 8 0 1 0-11.62 9"></path>
        <polyline points="13 11 9 17 15 17 11 23"></polyline>
      </svg>
      <span class="text align-middle">
        <span *ngIf="!reconnect">Connect GDrive</span>
        <span *ngIf="reconnect">Reconnect GDrive</span>
      </span>
    </button> -->

    <button *ngIf="!reconnect && !driveSynchronisationCompleted" [disabled]="gDriveSyncForm.invalid || driveSynchronisationInProgress"
      (click)="validateParentFolder()" type="button" class="btn btn-outline-success">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
        class="feather feather-check-circle">
        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
        <polyline points="22 4 12 14.01 9 11.01"></polyline>
      </svg>
      <span class="text align-middle">Enable Sync</span>
    </button>
  </div>
</ng-template>

<button type="button" class="btn btn-outline-success" (click)="open(content)">
  <span class="icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
      stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-refresh-cw">
      <polyline points="23 4 23 10 17 10"></polyline>
      <polyline points="1 20 1 14 7 14"></polyline>
      <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path>
    </svg>
  </span>
  <span class="text align-middle">
    <span *ngIf="!reconnect">GDrive Sync</span>
    <span *ngIf="reconnect">Reconnect GDrive</span>
  </span>
</button>