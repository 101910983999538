import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from 'src/app/shared/interfaces/api-response';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private httpClient: HttpClient
  ) { }

  delete(userId: number) {
    return this.httpClient.post<ApiResponse>('/users/delete', {
      userId,
    }, {
      observe: 'response',
    });
  }
}
