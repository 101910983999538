import packageInfo from '../../package.json';

export const environment = {
  production: false,
  environmentName: 'staging',
  companyName: 'Signogic Limited',
  appName: 'signogic',
  appVersion: packageInfo.version,
  apiUrl: 'https://api.staging.signogic.co.uk',
  googleSignInClientId: '585363388365-ss430hdbftj6826ohjgra4a81k76b2ib.apps.googleusercontent.com',
  ipRegistryApiKey: 'vci2zsqsovpxqq',
  googleAnalyticsId: 'G-Z9YGQQE1XC',
};