/**
 * https://stackoverflow.com/questions/34489916/how-to-load-external-scripts-dynamically-in-angular
 * https://medium.com/better-programming/angular-load-external-javascript-file-dynamically-3d14dde815cb
 */

import { Injectable } from '@angular/core';

interface Scripts {
  name: string,
  src: string
}

interface scriptLoadResponse {
  script: string;
  loaded: boolean;
  status: string;
}

export const ScriptStore: Scripts[] = [
  { name: 'google-sign-in2', src: 'https://apis.google.com/js/platform.js' },
  { name: 'googleIdentityServicesSDK', src: 'https://accounts.google.com/gsi/client' },
];

declare let document: any;

@Injectable({
  providedIn: 'root'
})
export class ScriptLoadingService {

  private scripts: Record<string, any> = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  public load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  private loadScript(name: string): Promise<scriptLoadResponse> {
    return new Promise((resolve, reject) => {
      if (!this.scripts[name].loaded) {
        //load script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {  // For IE
          script.onreadystatechange = () => {
            if (script.readyState === "loaded" || script.readyState === "complete") {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: 'Loaded' });
            }
          };
        } else {  // Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: 'Loaded' });
          };
        }
        script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      }
    });
  }
}
