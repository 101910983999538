import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { firstValueFrom, map, Observable, ReplaySubject, startWith, takeUntil } from 'rxjs';
import { InvitedUsersService } from 'src/app/core/services/invited/invited-users.service';
import { LoggerService } from 'src/app/core/services/logger/logger.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { InvitedUsers } from '../../interfaces/invited-users';

@Component({
  selector: 'app-invited-users',
  templateUrl: './invited-users.component.html',
  styleUrls: ['./invited-users.component.scss']
})
export class InvitedUsersComponent implements OnInit {

  private destroyed: ReplaySubject<boolean> = new ReplaySubject(1);

  public invitedUsers: InvitedUsers[];

  public invitedUsers$: Observable<InvitedUsers[]>;

  public filter = new FormControl("");

  constructor(
    private loggerService: LoggerService,
    private invitedUsersService: InvitedUsersService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  private init() {
    try {
      this.getInvitedUsers();
    } catch (error) {
      this.loggerService.error(
        'Error in running "init()" from invited component',
        error
      );
      return error;
    }
  }

  public getInvitedUsers() {
    try {
      this.invitedUsersService
        .getAll()
        .pipe(takeUntil(this.destroyed))
        .subscribe((response) => {
          if (response.status === 200) {
            const responseBody: any = response.body;

            if (responseBody.data.length > 0) {
              this.invitedUsers = responseBody.data;

              this.filterInvitedUsers();
            } else {
              // No invited users
              this.invitedUsers = [];
            }
          } else {
            this.loggerService.error("Error", response.statusText);
          }
        });
    } catch (error) {
      this.loggerService.error(
        'Error in running "getInvitedUsers()" from invited component',
        error
      );
      return error;
    }
  }

  private searchInvitedUsers(text: string): InvitedUsers[] {
    try {
      return this.invitedUsers.filter((user) => {
        const term = text.toLowerCase();
        return (
          user.email.toLowerCase().includes(term) ||
          user.role_name.toLowerCase().includes(term)
        );
      });
    } catch (error) {
      this.loggerService.error(
        'Error in running "searchInvitedUsers()" from invited component',
        error
      );
      return error;
    }
  }

  private filterInvitedUsers() {
    try {
      this.invitedUsers$ = this.filter.valueChanges.pipe(
        startWith(""),
        map((text: string) => this.searchInvitedUsers(text))
      );
    } catch (error) {
      this.loggerService.error(
        'Error in running "filterInvitedUsers()" from invited component',
        error
      );
      return error;
    }
  }

  public async deleteInvitedUser(invitedUserId: number) {
    try {
      if (confirm("Are you sure you want to delete this user?")) {
        const deleteInvitedUserResponse = await firstValueFrom(this.invitedUsersService.delete(invitedUserId));
        if (deleteInvitedUserResponse.status === 200) {
          const responseBody: any = deleteInvitedUserResponse.body;
          if (!responseBody.error) {
            this.toastService.show(responseBody.message, { classname: 'bg-success text-light', delay: 3000 });

            this.getInvitedUsers();
          } else {
            this.toastService.show(responseBody.message, { classname: 'bg-danger text-light', delay: 3000 });
          }
        } else {
          console.error('Error', deleteInvitedUserResponse.statusText);
        }
      }
    } catch (error) {
      this.loggerService.error(
        'Error in running "deleteInvitedUser()" from invited component',
        error
      );
      return error;
    }
  }

}
