import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEnGB from '@angular/common/locales/en-GB';
registerLocaleData(localeEnGB);
// https://angular.io/guide/i18n#i18n-pipes
// https://stackoverflow.com/questions/34904683/how-to-set-locale-in-datepipe-in-angular-2

import { CoreModule } from './core/core.module';
import { SharedModule } from '../app/shared/modules/shared.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './core/header/header.component';
import { FooterComponent } from './core/footer/footer.component';
import { LayoutComponent } from './core/layout/layout.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LayoutComponent
  ],
  imports: [
    BrowserModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    NgbModule
  ],
  providers: [
    // Setting en-GB locale as default
    {
      provide: LOCALE_ID,
      useValue: 'en-GB'
    },
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
