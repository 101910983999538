import { Injectable } from '@angular/core';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/shared/interfaces/api-response';

@Injectable({
  providedIn: 'root'
})
export class GDriveSyncService {

  constructor(private httpClient: HttpClient) { }

    public initiateSynchronisation(driveSynchronisationId: number): Observable<HttpResponse<ApiResponse>> {
    return this.httpClient.post<ApiResponse>('/gdrive/sync-drive', {
      'driveSynchronisationId': driveSynchronisationId
    }, {
      observe: 'response'
    });
  }
}
