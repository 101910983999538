<main>
  <div class="container-fluid">
    <div *ngIf="!invitedUsers" class="loader">
      <app-loader [text]="'Loading...'"></app-loader>
    </div>

    <!-- Using ng-template to avoid code duplication -->
    <ng-template #appUserInvite>
      <app-user-invite></app-user-invite>
    </ng-template>

    <div *ngIf="invitedUsers && invitedUsers?.length < 1" class="no-users">
      <div class="row">
        <div class="col">
          <div class="float-right">
            <ng-template [ngTemplateOutlet]="appUserInvite"></ng-template>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col text-center">
          <h3>No Invited Users</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-4 offset-4 mt-5 mb-3">
          <img src="assets/svg/add-user.svg" class="img-fluid" />
        </div>
      </div>
    </div>

    <div *ngIf="invitedUsers && invitedUsers?.length >= 1" class="row pt-3 pb-2 mb-3">
      <div class="col">
        <form class="float-left">
          <div class="form-group form-inline">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"
              viewBox="0 0 16 16">
              <path
                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </svg>
            <input class="form-control ml-2" type="text" placeholder="Search..." [formControl]="filter" />
          </div>
        </form>

        <div class="float-right">
          <ng-template [ngTemplateOutlet]="appUserInvite"></ng-template>
        </div>

        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Email</th>
              <th scope="col">Role</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let invitedUser of invitedUsers$ | async; let i = index" class="user-row">
              <th scope="row">{{ i + 1 }}</th>
              <td class="user-email">
                <a [href]="'mailto:' + invitedUser.email">
                  <ngb-highlight [result]="invitedUser.email" [term]="filter.value"></ngb-highlight>
                  <img class="ml-2 icon-sm user-email-icon" src="assets/icons/envelope-open.svg" />
                </a>
              </td>
              <td>
                <ngb-highlight [result]="invitedUser.role_name" [term]="filter.value"></ngb-highlight>
              </td>
              <td>
                <button type="button" class="btn btn-outline-danger"
                  (click)="deleteInvitedUser(invitedUser.invited_user_id)">
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</main>