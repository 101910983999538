import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  public alertClosed: boolean = false;
  @Input() type: string = 'warning';
  @Input() message: string;

  constructor(private changeDetector: ChangeDetectorRef) { }

  ngOnInit() {
  }

  close() {
    this.alertClosed = true;
    this.changeDetector.detectChanges();
  }

}
