import { Injectable } from "@angular/core";

import { LoggerService } from "../logger/logger.service";

@Injectable({
  providedIn: "root",
})
export class EncodeDecodeService {
  constructor(private loggerService: LoggerService) {}

  encode(
    payload:
      | string
      | number
      | Record<string, unknown>
      | Array<Record<string, unknown>>
  ): string {
    try {
      let encodableValue: string;

      if (
        typeof payload === "object" ||
        (Array.isArray(payload) && payload !== null)
      ) {
        encodableValue = JSON.stringify(payload);
      } else if (payload) {
        encodableValue = payload.toString();
      }

      // Encode
      const base64string = btoa(encodableValue);

      return base64string;
    } catch (error) {
      this.loggerService.error(
        'Error in running "encode()" from encode decode service',
        error
      );
      return error;
    }
  }

  decode(payload: string, object = false): any {
    try {
      // Decode
      const decodedString = atob(payload);
      let returnValue = decodedString;

      if (object) {
        returnValue = JSON.parse(decodedString);
      }

      return returnValue;
    } catch (error) {
      this.loggerService.error(
        'Error in running "decode()" from encode decode service',
        error
      );
      return error;
    }
  }
}
