import { Injectable } from '@angular/core';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionStorageService } from '../session-storage/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class GdriveAuthService {
  // @TODO
  // create behaviour subject and watch for changes
  private membershipId: string;


  constructor(
    private http: HttpClient,
    private sessionStorageService: SessionStorageService,
    ) {
    // this.sessionStorageService.sessionStorageSub.subscribe((status: boolean) => {
    //   console.log('Session storage changed', status);
    //   this.membershipId = this.sessionStorageService.getItem('membershipId');
    // });
    this.membershipId = this.sessionStorageService.getItem('membershipId');
  }

  /**
   * Requesting URL from server so user can get GDrive auth code
   */
  getAuthUrl(): Observable<HttpResponse<string>> {
    return this.http.get<string>(
      '/gdrive/get-auth-url', { observe: 'response' }
    );
  }

  getToken(code: string): Observable<HttpResponse<string>> {
    return this.http.post<string>('/gdrive/get-token', { 'authCode': code, 'membershipId': this.membershipId }, { observe: 'response' });
  }

  validateSharedDrive(id: string): Observable<HttpResponse<string>> {
    return this.http.post<string>('/gdrive/validate-shared-drive', { 'sharedDriveId': id, 'membershipId': this.membershipId }, { observe: 'response' });
  }
}
