import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

/**
 * Prefixes all requests with `environment.apiUrl`.
 */
@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.url.indexOf('/') === 0) {
            request = request.clone({ url: environment.apiUrl + request.url });
            return next.handle(request);
        } else {
            return next.handle(request);
        }
    }

}