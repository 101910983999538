import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { LoggerService } from "../../../../../core/services/logger/logger.service";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { DomainCheckService } from "../../../../../core/services/domain-check/domain-check.service";
import { firstValueFrom, Observable, ReplaySubject, takeUntil } from "rxjs";
import { InvitedUsersService } from "../../../../../core/services/invited/invited-users.service";
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { InvitedUsersComponent } from '../../../invited-users/invited-users.component';

@Component({
  selector: "app-user-invite",
  templateUrl: "./user-invite.component.html",
  styleUrls: ["./user-invite.component.scss"],
})
export class UserInviteComponent implements OnInit {
  public inviteUserForm: FormGroup;

  public accessLevels = [
    {
      roleId: 9,
      name: "Default",
    },
    {
      roleId: 8,
      name: "Uploader",
    },
    {
      roleId: 7,
      name: "Moderator",
    },
    {
      roleId: 6,
      name: "Organisation Administrator",
    },
    {
      roleId: 5,
      name: "Account Administrator",
    },
  ];

  private destroyed: ReplaySubject<boolean> = new ReplaySubject(1);

  // Initialising with true,
  // because we dont want to start with the warning message
  public googleDomain = true;

  constructor(
    private loggerService: LoggerService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private domainCheckService: DomainCheckService,
    private invitedUsersService: InvitedUsersService,
    private toastService: ToastService,
    private invitedUsersComponent: InvitedUsersComponent,
  ) {}

  ngOnInit(): void {
    this.generateForms();

    this.onFormChanges();
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  private generateForms() {
    this.inviteUserForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      accessLevel: [this.accessLevels[0], [Validators.required]],
    });
  }

  private onFormChanges(): void {
    try {
      // Listening for specific changes.

      // Checking email domain
      let handle: ReturnType<typeof setTimeout>;
      this.inviteUserForm
        .get("email")
        .valueChanges.subscribe((value) => {
          if (!this.inviteUserForm.pristine && value) {
            if (handle) {
              // Removing handle to prevent multiple invocations
              clearTimeout(handle);
            }

            handle = setTimeout(() => {
              this.checkGoogleDomain();
            }, 5e2);
          }
        });
    } catch (error) {
      this.loggerService.error(
        'Error in running "onFormChanges()" from screens configurations',
        error
      );
      return error;
    }
  }

  public checkGoogleDomain() {
    try {
      return new Promise((resolve) => {
        const formValues = this.inviteUserForm.value;
        const emailDomain = formValues.email.split("@").pop();
        this.domainCheckService
          .getGoogleDNSRecord(emailDomain)
          .pipe(takeUntil(this.destroyed))
          .subscribe((response) => {
            const mxRecords = response.body.Answer;

            if (mxRecords && mxRecords.length > 0) {
              for (let index = 0; index < mxRecords.length; index += 1) {
                const element = mxRecords[index];

                const value = element.data;

                const googleMXDomain = value.endsWith("google.com.");
                const googleMailMXDomain = value.endsWith("googlemail.com.");

                if (googleMXDomain || googleMailMXDomain) {
                  this.googleDomain = true;

                  break;
                } else {
                  this.googleDomain = false;
                }
              }
            } else {
              this.googleDomain = false;
            }
            resolve(true);
          });
      });
    } catch (error) {
      this.loggerService.error(
        'Error in running "checkGoogleDomain()" from users component',
        error
      );
      return error;
    }
  }

  public async inviteUser() {
    try {
      const email = this.inviteUserForm.get('email').value;
      const accessLevel = this.inviteUserForm.get('accessLevel').value;
      const params = {
        email,
        roleId: accessLevel.roleId
      }
      
      const invitedInsertResponse = await firstValueFrom(this.invitedUsersService.insert(params));
      if (invitedInsertResponse.status === 200) {
        const responseBody: any = invitedInsertResponse.body;
        // console.log('responseBody', responseBody);
        if (!responseBody.error) {
          // Success

          this.toastService.show(responseBody.message, { classname: 'bg-success text-light', delay: 3000 });

          // Resetting form after successful save
          this.inviteUserForm.reset();

          this.invitedUsersComponent.getInvitedUsers();

          this.modalService.dismissAll('Invited user');
        } else {
          // Error

          this.loggerService.error(invitedInsertResponse.statusText, params);

          this.toastService.show(responseBody.message, { classname: 'bg-danger text-light', delay: 3000 });
        }
      } else {
        this.loggerService.error(invitedInsertResponse.statusText);
      }
    } catch (error) {
      this.loggerService.error(
        'Error in running "inviteUser()" from users component',
        error
      );
      return error;
    }
  }

  public openInviteUserModal(content) {
    try {
      this.modalService
        .open(content, { ariaLabelledBy: "modal-basic-title", centered: true })
        .result.then(
          (result) => {
            if (result === 1) {
              // this.insertPlaylist();
            }
          },
          (reason) => {
            // Modal close
            this.inviteUserForm.reset();
            this.inviteUserForm.patchValue({
              accessLevel: this.accessLevels[0],
            });
          }
        );
    } catch (error) {
      this.loggerService.error(
        'Error in running "openInviteUserModal()" from users component',
        error
      );
      return error;
    }
  }
}
