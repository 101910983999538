import { Injectable } from "@angular/core";

import { LoggerService } from "../logger/logger.service";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Observable, BehaviorSubject, firstValueFrom } from "rxjs";
import { ApiResponse } from "src/app/shared/interfaces/api-response";
import { SessionStorageService } from "../session-storage/session-storage.service";
import { MembershipService } from "../../services/membership/membership.service";
import { DrivesService } from "../../services/drives/drives.service";
import { environment } from "../../../../environments/environment";
import { LocalStorageService } from "../local-storage/local-storage.service";
import { OrganisationsService } from "../../services/organisations/organisations.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class UserService {
  logInCompleted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  logInError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  userDetails: any;
  memberships: Array<Record<string, unknown>>;

  constructor(
    private loggerService: LoggerService,
    private httpClient: HttpClient,
    private sessionStorageService: SessionStorageService,
    private membershipService: MembershipService,
    private drivesService: DrivesService,
    private localStorageService: LocalStorageService,
    private organisationsService: OrganisationsService,
    private router: Router
  ) {}

  public isSignedIn() {
    try {
      return this.getToken() ? true : false;
    } catch (error) {
      this.loggerService.error(
        'Error in running "isSignedIn()" from user service',
        error
      );
      return error;
    }
  }

  public getUserDetails() {
    try {
      this.userDetails = this.sessionStorageService.getUser();
      return this.userDetails;
    } catch (error) {
      this.loggerService.error(
        'Error in running "getUserDetails()" from user service',
        error
      );
      return error;
    }
  }

  /**
   * Checking for token in session storage.
   * This can be renamed to "isSignedIn".
   */
  private getToken() {
    try {
      const token: string = this.sessionStorageService.getToken();
      return token;
    } catch (error) {
      this.loggerService.error(
        'Error in running "getToken()" from user service',
        error
      );
      return error;
    }
  }

  /**
   * Returns user type from DB
   */
  public getUserType() {
    // @TODO
    return "user";
  }

  /**
   * Return user role from DB
   */
  public getUserRole() {
    // @TODO
    // 0 - Super admin
    // 1 - VAR
    // 2 - admin
    // 3 - user?
    return 3;
  }

  public async googleLogInSuccessHandler(userDetails) {
    try {
      this.userDetails = userDetails;

      await this.storeUserDetails();
    } catch (error) {
      this.loggerService.error(
        'Error in running "googleLogInSuccessHandler()" from user service',
        error
      );
      return error;
    }
  }

  /**
   * IP Info and user agent specific data
   */
  private getIPInfo(): Observable<any> {
    try {
      return this.httpClient.get<any>(
        "https://api.ipregistry.co/?key=" + environment.ipRegistryApiKey
      );
    } catch (error) {
      this.loggerService.error(
        'Error in running "getIPInfo()" from user service',
        error
      );
      return error;
    }
  }

  private finaliseLogin(response: HttpResponse<ApiResponse>) {
    try {
      if (response.body.error) {
        this.logInError.next(true);
        throw new Error(response.body.message);
      } else {
        // All done, login completed
        this.logInCompleted.next(true);
      }
    } catch (error) {
      this.loggerService.error(
        'Error in running "finaliseLogin()" from user service',
        error
      );
      return error;
    }
  }

  private async evaluateLogin(info: any) {
    try {
      const recordLoginResponse = await firstValueFrom(
        this.recordLogin(info)
      );
      if (recordLoginResponse) {
        await firstValueFrom(this.getCloudFrontCookies());

        this.finaliseLogin(recordLoginResponse);
      }
    } catch (error) {
      this.loggerService.error(
        'Error in running "evaluateLogin()" from user service',
        error
      );
      return error;
    }
  }

  private recordLogin(
    info: any
  ): Observable<HttpResponse<ApiResponse>> {
    try {
      return this.httpClient.post<ApiResponse>("/login/record-login", info, {
        observe: "response",
      });
    } catch (error) {
      this.loggerService.error(
        'Error in running "recordLogin()" from user service',
        error
      );
      return error;
    }
  }

  private getCloudFrontCookies(): Observable<HttpResponse<ApiResponse>> {
    try {
      return this.httpClient.post<ApiResponse>(
        "/aws/set-cloudfront-cookies",
        {},
        {
          observe: "response",
          withCredentials: true,
        }
      );
    } catch (error) {
      this.loggerService.error(
        'Error in running "getCloudFrontCookies()" from user service',
        error
      );
      return error;
    }
  }

  private runLogoutActions(): Observable<HttpResponse<ApiResponse>> {
    return this.httpClient.post<ApiResponse>(
      "/logout",
      {},
      {
        observe: "response",
        withCredentials: true,
      }
    );
  }

  private async storeUserDetails() {
    try {
      // Storing token
      this.sessionStorageService.setToken(this.userDetails.token);

      // Storing membership ID
      await this.storeMembershipId();

      return true;
    } catch (error) {
      this.loggerService.error(
        'Error in running "storeUserDetails()" from user service',
        error
      );
      return error;
    }
  }

  private async storeMembershipId() {
    try {
      await this.storeCurrentUser();

      const getMembershipResponse = await firstValueFrom(
        this.membershipService.getMemberships()
      );

      if (
        getMembershipResponse.status == 200 &&
        !getMembershipResponse.body.error
      ) {
        if (
          getMembershipResponse.body.data.rows &&
          getMembershipResponse.body.data.rowCount
        ) {
          this.memberships = getMembershipResponse.body.data.rows;

          await this.storeMemberships();

          this.membershipService.setCurrentMembershipId(
            getMembershipResponse.body.data.rows[0].membership_id
          );

          this.membershipService.currentMembership.next(
            getMembershipResponse.body.data.rows[0]
          );

          const organisationId =
            getMembershipResponse.body.data.rows[0].organisation_id;

          this.storeOrganisationId(organisationId);

          await this.storeDriveId(organisationId);

          const ipInfoResponse = await firstValueFrom(this.getIPInfo());

          await this.evaluateLogin(ipInfoResponse);
        }
      } else {
        this.loggerService.error("Error in getting membership details", getMembershipResponse.statusText);

        // User does not have membership ID
        this.unauthorisedUser();
      }
    } catch (error) {
      this.loggerService.error(
        'Error in running "storeMembershipId()" from user service',
        error
      );
      return error;
    }
  }

  public resetUserDetails() {
    return new Promise((resolve, reject) => {
      this.membershipService.getMemberships().subscribe((response) => {
        if (response.status == 200 && !response.body.error) {
          if (response.body.data.rows && response.body.data["rows"].length) {
            this.memberships = response.body.data.rows;
            // Need to get "userDetails" for storing "currentUser"
            this.getUserDetails();
            this.storeCurrentUser();

            resolve(true);
          }
        } else {
          console.error("Error", response.statusText);
          // User does not have membership ID
          this.unauthorisedUser();

          reject();
        }
      });
    });
  }

  private storeOrganisationId(organisationId: number) {
    this.organisationsService.setCurrentOrganisationId(organisationId);
  }

  private async storeDriveId(organisationId: number) {
    try {
      const getDriveResponse = await firstValueFrom(
        this.drivesService.getDrive(organisationId)
      );

      if (getDriveResponse.status == 200 && !getDriveResponse.body.error) {
        if (
          getDriveResponse.body.data.rows &&
          getDriveResponse.body.data["rows"].length
        ) {
          this.drivesService.setCurrentDriveDetails(
            getDriveResponse.body.data.rows[0]
          );
        }
      } else {
        this.loggerService.error("Error", getDriveResponse.statusText);
      }
    } catch (error) {
      this.loggerService.error(
        'Error in running "storeDriveId()" from user service',
        error
      );
      return error;
    }
  }

  private storeCurrentUser(): Promise<boolean> {
    try {
      return new Promise((resolve) => {
        const currentUser: Record<string, unknown> = {
          userId: this.userDetails.userId,
          name: this.userDetails.name,
          firstName: this.userDetails.given_name,
          lastName: this.userDetails.family_name,
          email: this.userDetails.email,
          picture: this.userDetails.picture,
        };

        // Storing user details
        this.sessionStorageService.setUser(currentUser);

        // Setting theme if not set
        if (!this.localStorageService.getTheme()) {
          this.localStorageService.setTheme();
        }

        resolve(true);
      });
    } catch (error) {
      this.loggerService.error(
        'Error in running "storeCurrentUser()" from user service',
        error
      );
      return error;
    }
  }

  private storeMemberships(): Promise<boolean> {
    try {
      return new Promise((resolve) => {
        // Storing user details
        this.sessionStorageService.setMemberships(this.memberships);

        resolve(true);
      });
    } catch (error) {
      this.loggerService.error(
        'Error in running "storeMemberships()" from user service',
        error
      );
      return error;
    }
  }

  public async signOut() {
    try {
      sessionStorage.clear();
  
      await firstValueFrom(this.runLogoutActions());

      this.router.navigate(['/auth']);
    } catch (error) {
      this.loggerService.error(
        'Error in running "signOut()" from user service',
        error
      );
      return error;
    }
  }

  private async unauthorisedUser() {
    try {
      this.logInError.next(true);

      console.error("No membership found.");
    } catch (error) {
      this.loggerService.error(
        'Error in running "unauthorisedUser()" from user service',
        error
      );
      return error;
    }
  }
}
