import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { LoggerService } from "../logger/logger.service";
@Injectable({
  providedIn: "root",
})
export class DomainCheckService {
  constructor(
    private loggerService: LoggerService,
    private httpClient: HttpClient
  ) {}

  public getGoogleDNSRecord(domain: string): Observable<HttpResponse<any>> {
    try {
      const params = new HttpParams().appendAll({
        name: domain,
        type: "MX",
      });

      return this.httpClient.get<any>("https://dns.google.com/resolve", {
        params,
        observe: "response",
      });
    } catch (error) {
      this.loggerService.error("Error in getting DNS record", error);
      return error;
    }
  }
}
