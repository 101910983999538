/**
 * @author Jyothish Mathew
 * 
 * On html tried "ng-container" and "div" with ngSwitch but was not working
 * properly. So using ngIf now.
 * 
 * This can be achieved in "app.conponent" but don't want to mess that file.
 * Plus this approach is expandable.
 */
import { Component, OnInit } from '@angular/core';

import { Router, ActivationEnd } from '@angular/router';

// Usage - https://github.com/ngrx/platform/issues/564
import { filter } from 'rxjs/operators';
// Usage - https://angular.io/guide/set-document-title
import { Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  layout: string;
  appName: string;

  constructor(
    private router: Router,
    private titleService: Title
    ) {
    this.layout = 'base';
  }

  ngOnInit() {
    this.appName = environment.appName;
    /**
 * The is three options bellow to get route data.
 * Left unused options for now, use then if needed.
 * 
 * CAN DELETE IF NO LONGER NEEDED
 */
    // this.router.events.subscribe((data) => {
    //   if (data instanceof RoutesRecognized) {
    //     console.log('1', data.state._root.children[0].children[0].value.data);
    //   }
    // });

    // this.router.events.pipe(filter(event => event instanceof ActivationStart))
    // .subscribe(event => {
    //   console.log('2', event['snapshot'].data);
    // });

    this.router.events.pipe(filter(event => event instanceof ActivationEnd && event.snapshot.children.length == 0))
      .subscribe((event: ActivationEnd) => {
        const snapshot: any = event.snapshot;
        this.layout = snapshot.data.layout;
        // console.log('3', snapshot._routerState.url);
        this.titleService.setTitle(this.titleMaker(snapshot._routerState.url));
      });

  }

  /**
   * titleMaker
   * This will remove first character from parameter and make the remaining
   * first character uppercase.
   * 
   * @param text 
   */
  titleMaker(text: string) {
    let title = text.substring(1);
    // Capitalising first letter
    const amendedAppName = this.appName.charAt(0).toUpperCase() + this.appName.slice(1);
    title = amendedAppName + ' - ' + title.charAt(0).toUpperCase() + title.substring(1);
    return title;
  }

}
