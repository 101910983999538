import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filesize'
})
export class FilesizePipe implements PipeTransform {

  // https://gist.github.com/lanqy/5193417
  // transform(bytes: any, ...args: any[]): any {
  transform(bytes: any): string {
    if (!bytes || bytes === 0) return 'n/a';

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    const baseE = (Math.log(bytes) / Math.log(1024));
    const floored = Math.floor(baseE);
    const stringRepresentation = floored.toString();
    const i = parseInt(stringRepresentation, 10);

    if (i === 0) return `${bytes} ${sizes[i]}`;
    return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`;
  }

}
