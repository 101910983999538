<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      <span>Invite User</span>
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="inviteUserForm">
      <div class="form-group">
        <label for="name">Email *:</label>
        <span
          *ngIf="
            inviteUserForm.invalid && (inviteUserForm.touched || inviteUserForm.dirty)
          "
          class="text-danger float-right"
          >Valid email required</span
        >
        <input
          type="email"
          ngbAutofocus
          formControlName="email"
          class="form-control"
          id="email"
          aria-describedby="email"
          placeholder="User email"
          required
        />
      </div>
      <div class="form-group">
        <label for="accessLevel" placement="top" ngbTooltip="Delete"
          >Access Level *:</label
        >
        <select
          formControlName="accessLevel"
          class="form-control"
          id="accessLevel"
          aria-describedby="type"
        >
          <option
            *ngFor="let accessLevel of accessLevels"
            [ngValue]="accessLevel"
          >
            {{ accessLevel.name }}
          </option>
        </select>
      </div>
    </form>

    <div *ngIf="inviteUserForm.valid && !googleDomain" class="alert alert-danger mt-3" role="alert">
      <div class="d-flex align-items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
          viewBox="0 0 16 16"
          role="img"
          aria-label="Warning:"
        >
          <path
            d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
          />
        </svg>
        <h6 class="alert-heading m-0 ml-1">
          Please provide a Google Workspace email or Gmail.
        </h6>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-danger"
      (click)="modal.dismiss()"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-x-circle"
      >
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="15" y1="9" x2="9" y2="15"></line>
        <line x1="9" y1="9" x2="15" y2="15"></line>
      </svg>
      <span class="text align-middle">Cancel</span>
    </button>

    <button
      type="button"
      [disabled]="!inviteUserForm.valid || !googleDomain"
      class="btn btn-outline-success"
      (click)="inviteUser()"
    >
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-envelope-open-fill" viewBox="0 0 16 16">
      <path d="M8.941.435a2 2 0 0 0-1.882 0l-6 3.2A2 2 0 0 0 0 5.4v.313l6.709 3.933L8 8.928l1.291.717L16 5.715V5.4a2 2 0 0 0-1.059-1.765l-6-3.2zM16 6.873l-5.693 3.337L16 13.372v-6.5zm-.059 7.611L8 10.072.059 14.484A2 2 0 0 0 2 16h12a2 2 0 0 0 1.941-1.516zM0 13.373l5.693-3.163L0 6.873v6.5z"/>
    </svg>
      <span class="text align-middle">Invite User</span>
    </button>
  </div>
</ng-template>

<button
  type="button"
  class="btn btn-outline-success"
  (click)="openInviteUserModal(content)"
>
  <span class="icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-envelope-open-fill" viewBox="0 0 16 16">
      <path d="M8.941.435a2 2 0 0 0-1.882 0l-6 3.2A2 2 0 0 0 0 5.4v.313l6.709 3.933L8 8.928l1.291.717L16 5.715V5.4a2 2 0 0 0-1.059-1.765l-6-3.2zM16 6.873l-5.693 3.337L16 13.372v-6.5zm-.059 7.611L8 10.072.059 14.484A2 2 0 0 0 2 16h12a2 2 0 0 0 1.941-1.516zM0 13.373l5.693-3.163L0 6.873v6.5z"/>
    </svg>
  </span>
  <span class="text align-middle">Invite User</span>
</button>
