import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { ApiResponse } from 'src/app/shared/interfaces/api-response';
import { SessionStorageService } from '../session-storage/session-storage.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrganisationsService {

  public currentOrganisationId: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  
  constructor(
    private httpClient: HttpClient,
    private sessionStorageService: SessionStorageService
    ) { }

  public updateLogoKey(logoName: string, organisationId: number) {
    return this.httpClient.post<ApiResponse>('/organisations/update-logo-key', {
      logoName,
      organisationId
    }, {
      observe: 'response',
    });
  }

  public setCurrentOrganisationId(organisationId: number) {
    this.sessionStorageService.setItem('organisationId', organisationId.toString());

    this.currentOrganisationId.next(organisationId);
  }

  public getCurrentOrganisationId() {
    return Number(this.sessionStorageService.getItem('organisationId'));
  }

  public updateSettings(parameters: any) {
    return this.httpClient.post<ApiResponse>('/organisations/update-settings', parameters, {
      observe: 'response',
    });
  }
}
