import { Injectable } from '@angular/core';

import { LoggerService } from '../logger/logger.service';

// Declare ga as a function to access the JS code in TS
// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(
    private loggerService: LoggerService
  ) { }

  public startTrackerWithId(id: string): void {
    try {
      gtag("config", id);
    } catch (error) {
      this.loggerService.error('Error in running "startTrackerWithId()" from analytics service', error, id);
      return error;
    }
  }

  // trackView(pageUrl: string, screenName: string) {}

  // trackEvent(category, action, label?, value?) {}
}
