/**
 * @author Jyothish Mathew
 *
 * Why ViewEncapsulation? - The class added to innerHTML is ignored because by default the
 * encapsulation is Emulated. Which means Angular prevents styles
 * from intercepting inside and outside of the component. You should
 * change the encapsulation to None in your component. This way, you'll
 * be able to define classes wherever you want: inside styles or in a
 * separate .css, .scss or .less style-sheet (it doesn't matter) and
 * Angular will add them to the DOM automatically.
 * https://stackoverflow.com/questions/44210786/style-not-working-for-innerhtml-in-angular-2-typescript
 */

import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectorRef,
} from "@angular/core";

import { DomSanitizer } from "@angular/platform-browser";
import { ReplaySubject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { SideMenuItems } from "../../shared/interfaces/side-menu-items";
// import { GoogleAuthService } from '../services/google-auth/google-auth.service';
import { UserService } from "../../core/services/user/user.service";
import { MembershipService } from "../../core/services/membership/membership.service";
import { LoaderService } from "../../core/services/loader/loader.service";
import { LoggerService } from "../services/logger/logger.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  // See "Why ViewEncapsulation" for explanation
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  appName: string;

  isExpanded = true;

  menuItems: SideMenuItems[];

  currentUser: any;

  private destroyed: ReplaySubject<boolean> = new ReplaySubject(1);

  // public currentMembershipId: number;
  public currentMembership: any;

  public loading: boolean;

  public sidebarHeight;

  constructor(
    private loggerService: LoggerService,
    private sanitizer: DomSanitizer,
    // private googleAuthService: GoogleAuthService,
    private userService: UserService,
    private membershipService: MembershipService,
    private loaderService: LoaderService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  private init(): void {
    try {
      this.appName = environment.appName;
      this.menuItems = [
        {
          title: "Screens",
          link: "/screens/home",
          icon: this.sanitizer.bypassSecurityTrustHtml(
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-tv"><rect x="2" y="7" width="20" height="15" rx="2" ry="2"></rect><polyline points="17 2 12 7 7 2"></polyline></svg>'
          ),
          minimumAccessLevel: 3,
          allowedRoleTypes: ["normal", "support", "admin"],
        },
        {
          title: "Content",
          link: "/content",
          icon: this.sanitizer.bypassSecurityTrustHtml(
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>'
          ),
          minimumAccessLevel: 3,
          allowedRoleTypes: ["normal", "support", "admin"],
        },
        {
          title: "Playlists",
          link: "/playlists",
          icon: this.sanitizer.bypassSecurityTrustHtml(
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-list"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3" y2="6"></line><line x1="3" y1="12" x2="3" y2="12"></line><line x1="3" y1="18" x2="3" y2="18"></line></svg>'
          ),
          minimumAccessLevel: 3,
          allowedRoleTypes: ["normal", "support", "admin"],
        },
        {
          title: "Schedules",
          link: "/schedules",
          icon: this.sanitizer.bypassSecurityTrustHtml(
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>'
          ),
          minimumAccessLevel: 3,
          allowedRoleTypes: ["normal", "support", "admin"],
        },
        {
          title: "Links",
          link: "/links/home",
          icon: this.sanitizer.bypassSecurityTrustHtml(
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-link" viewBox="0 0 16 16"><path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"/><path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z"/></svg>'
          ),
          minimumAccessLevel: 3,
          allowedRoleTypes: ["normal", "support", "admin"],
        },
        {
          title: "Apps",
          link: "/apps/home",
          icon: this.sanitizer.bypassSecurityTrustHtml(
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16"><path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/></svg>'
          ),
          minimumAccessLevel: 3,
          allowedRoleTypes: ["normal", "support", "admin"],
        },
        // {
        //   title: 'Accounts',
        //   link: '/accounts',
        //   icon: this.sanitizer.bypassSecurityTrustHtml('<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-tv"><rect x="2" y="7" width="20" height="15" rx="2" ry="2"></rect><polyline points="17 2 12 7 7 2"></polyline></svg>'),
        //   // VAR's can create accounts
        //   minimumAccessLevel: 6,
        //   allowedRoleTypes: ['sales', 'admin'],
        // },
        // {
        //   title: 'VAR',
        //   link: '/var',
        //   icon: this.sanitizer.bypassSecurityTrustHtml('<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-tv"><rect x="2" y="7" width="20" height="15" rx="2" ry="2"></rect><polyline points="17 2 12 7 7 2"></polyline></svg>'),
        //   // Distributor's can create VAR's
        //   minimumAccessLevel: 7,
        //   allowedRoleTypes: ['sales', 'admin'],
        // },
        { 
          title: 'Admin',
          link: '/admin',
          icon: this.sanitizer.bypassSecurityTrustHtml('<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-globe"><circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path></svg>'),
          minimumAccessLevel: 5,
          allowedRoleTypes: ['normal', 'support', 'admin'],
        },
        // {
        //   title: 'SAdmin',
        //   link: '/super-admin',
        //   icon: this.sanitizer.bypassSecurityTrustHtml('<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-bricks" viewBox="0 0 16 16"><path d="M0 .5A.5.5 0 0 1 .5 0h15a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H14v2h1.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H14v2h1.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5H2v-2H.5a.5.5 0 0 1-.5-.5v-3A.5.5 0 0 1 .5 6H2V4H.5a.5.5 0 0 1-.5-.5v-3zM3 4v2h4.5V4H3zm5.5 0v2H13V4H8.5zM3 10v2h4.5v-2H3zm5.5 0v2H13v-2H8.5zM1 1v2h3.5V1H1zm4.5 0v2h5V1h-5zm6 0v2H15V1h-3.5zM1 7v2h3.5V7H1zm4.5 0v2h5V7h-5zm6 0v2H15V7h-3.5zM1 13v2h3.5v-2H1zm4.5 0v2h5v-2h-5zm6 0v2H15v-2h-3.5z"/></svg>'),
        //   minimumAccessLevel: 9,
        //   allowedRoleTypes: ['support', 'admin'],
        // },
        {
          title: "Settings",
          link: "/settings",
          icon: this.sanitizer.bypassSecurityTrustHtml(
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>'
          ),
          minimumAccessLevel: 1,
          allowedRoleTypes: ["normal", "support", "admin"],
        },
      ];

      this.setInitialValues();
      this.getUserDetails();

      this.membershipService.currentMembership
        .pipe(takeUntil(this.destroyed))
        .subscribe((membership) => {
          this.currentMembership = membership;
        });
      this.runLoader();

      this.getWindowHeight();
    } catch (error) {
      this.loggerService.error(
        'Error in running "init()" from settings home',
        error
      );
      return error;
    }
  }

  /**
   * Found a better place for initialising, move this :)
   */
  private setInitialValues() {
    try {
      // Setting currentMembershipId
      const currentMembershipId =
        this.membershipService.getCurrentMembershipId();
      this.membershipService.currentMembershipId.next(currentMembershipId);

      this.membershipService
        .getCurrentMembership()
        .then((currentMembership: Record<string, unknown>) => {
          // Setting currentMembership
          this.membershipService.currentMembership.next(currentMembership);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      this.loggerService.error(
        'Error in running "setInitialValues()" from settings home',
        error
      );
      return error;
    }
  }

  getUserDetails() {
    try {
      this.currentUser = this.userService.getUserDetails();
    } catch (error) {
      this.loggerService.error(
        'Error in running "getUserDetails()" from settings home',
        error
      );
      return error;
    }
  }

  async logOut() {
    try {
      this.userService.signOut();
    } catch (error) {
      this.loggerService.error(
        'Error in running "logOut()" from settings home',
        error
      );
      return error;
    }
  }

  // https://stackoverflow.com/questions/35600685/what-is-angular2-way-of-creating-global-keyboard-shortcuts-a-k-a-hotkeys
  // https://netbasal.com/diy-keyboard-shortcuts-in-your-angular-application-4704734547a2
  toggleSidebar() {
    try {
      this.isExpanded = !this.isExpanded;

      // let minWidth: number;

      // if (this.isExpanded) {
      //   minWidth = 90;
      // } else {
      //   minWidth = 95;
      // }

      // const orientation = window.screen.orientation;
      // if (orientation.type !== 'landscape-primary') {
      //   // Portrait
      //   minWidth = minWidth - 5;
      // }

      // document.getElementsByTagName('main')[0].style.minWidth = minWidth + 'vw';
    } catch (error) {
      this.loggerService.error(
        'Error in running "toggleSidebar()" from settings home',
        error
      );
      return error;
    }
  }

  private runLoader() {
    try {
      this.loaderService.isLoading
        .pipe(takeUntil(this.destroyed))
        .subscribe((v) => {
          this.loading = v;
          // Change detector is only for this loader, if loader is no longer in service can safely remove.
          this.changeDetectorRef.detectChanges();
        });
    } catch (error) {
      this.loggerService.error(
        'Error in running "runLoader()" from settings home',
        error
      );
      return error;
    }
  }

  // @TODO
  // Need some fine tuning
  private getWindowHeight() {
    try {
      const realHeight = window.screen.height * window.devicePixelRatio;

      this.sidebarHeight = realHeight + "px";

      window.addEventListener("resize", () => {
        // console.log('Resized', 'IH', window.innerHeight, 'OH', window.outerHeight);
        this.sidebarHeight = document.body.clientHeight + "px";
        // console.log('document.body.scrollHeight', document.body.scrollHeight);
        // console.log('document.documentElement.scrollHeight', document.documentElement.scrollHeight);
      });

      // More info - https://developer.mozilla.org/en-US/docs/Web/API/Document/scroll_event
      document.addEventListener("scroll", () => {
        this.sidebarHeight = document.body.clientHeight + "px";
      });

      // More info - https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver/observe
      // const resizeObserver = new ResizeObserver(entries => {
      //     // console.log('Body height changed:', entries[0].target.clientHeight);
      //     this.sidebarHeight = document.body.clientHeight + 'px';
      //   }
      // );

      // // start observing a DOM node
      // resizeObserver.observe(document.body)
    } catch (error) {
      this.loggerService.error(
        'Error in running "getWindowHeight()" from settings home',
        error
      );
      return error;
    }
  }
}
