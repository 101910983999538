import { Injectable } from "@angular/core";

import { LoggerService } from "../logger/logger.service";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { ApiResponse } from "src/app/shared/interfaces/api-response";
import { SessionStorageService } from "../session-storage/session-storage.service";
import { OrganisationsService } from '../organisations/organisations.service';

@Injectable({
  providedIn: "root",
})
export class MembershipService {
  public currentMembershipId: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  public currentMembership: BehaviorSubject<Record<string, unknown>> =
    new BehaviorSubject<Record<string, unknown>>({});
  public currentAccessLevel: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);

  constructor(
    private loggerService: LoggerService,
    private httpClient: HttpClient,
    private sessionStorageService: SessionStorageService,
    private organisationsService: OrganisationsService
  ) {}

  public getMemberships(): Observable<HttpResponse<ApiResponse>> {
    // return this.httpClient.post<ApiResponse>('/memberships/membership-details', {}, {
    //   observe: 'response'
    // });
    return this.httpClient.get<ApiResponse>("/memberships/membership-details", {
      observe: "response",
    });
  }

  public getMembershipsWithUsers(): Observable<HttpResponse<ApiResponse>> {
    try {
      const organisationId = this.organisationsService.getCurrentOrganisationId();

      const params = new HttpParams().append(
        "organisationId",
        organisationId.toString()
      );

      return this.httpClient.get<ApiResponse>(
        "/memberships/memberships-with-users",
        {
          params,
          observe: "response",
        }
      );
    } catch (error) {
      this.loggerService.error("Error in getting membership with users", error);
      return error;
    }
  }

  public setCurrentMembershipId(membershipId: string) {
    this.sessionStorageService.setItem("membershipId", membershipId);

    this.currentMembershipId.next(Number(membershipId));
  }

  // public setCurrentMembership(membership: object) {
  //   this.sessionStorageService.setItem('membership', JSON.stringify(membership));

  //   this.currentMembership.next(membership);
  // }

  public getCurrentMembershipId() {
    return Number(this.sessionStorageService.getItem("membershipId"));
  }

  public getCurrentMembership() {
    return new Promise((resolve, reject) => {
      const memberships = this.sessionStorageService.getMemberships();
      // const currentUser = this.sessionStorageService.getUser();

      const currentMembershipId = this.getCurrentMembershipId();

      // Getting current membership
      memberships.forEach((membership) => {
        if (membership.membership_id == currentMembershipId) {
          resolve(membership);
        }
      });
    });
  }
}
