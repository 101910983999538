import { Component, OnInit } from "@angular/core";

import { LoggerService } from "../../../core/services/logger/logger.service";
import { GoogleAuthService } from "../../../core/services/google-auth/google-auth.service";

@Component({
  selector: "app-google-auth",
  templateUrl: "./google-auth.component.html",
  styleUrls: ["./google-auth.component.scss"],
})
export class GoogleAuthComponent implements OnInit {
  constructor(
    private loggerService: LoggerService,
    private googleAuthService: GoogleAuthService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  private init(): void {
    try {
      this.googleAuthService.init();
    } catch (error) {
      this.loggerService.error(
        'Error in running "init()" from google auth component',
        error
      );
      return error;
    }
  }
}
